/* eslint-disable react/function-component-definition */
import * as React from 'react';

import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginSocialApple, LoginSocialGoogle } from 'reactjs-social-login';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { setUser } from '../../../redux/actions/user';
import AxiosAll from '../../../services/axiosAll';
import notify from '../../../utils/notify';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import CheckBoxFiled from '../../universal/InputTypeText/ChceckBoxFiled';
import TextFieldInput from '../../universal/InputTypeText/TextField';
import { globalWindow, notifyMessages } from '../../../core/constant';

export default function SignupTab() {
  const [isPasswordShow, setIsPasswordShow] = useState(true);
  const [isConfirmShow, setIsConfirmShow] = useState(true);
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);
  const params = new URLSearchParams(globalWindow.location.search);
  const referralCode = params.get('referral_code');

  const REDIRECT_URI = `${process.env.REACT_APP_SELLER_APP_URL}signup`;

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    terms: false,
    marketing: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[a-zA-Z ]+$/, 'FirstName must be letters only')
      .required('First Name is required'),
    lastName: Yup.string()
      .matches(/^[a-zA-Z ]+$/, 'Surname must be letters only')
      .required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      // check if email domain is valid
      .matches(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email address',
      )
      .required('Email is required')
      .trim(),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
        'Oops! Your password must contain a capital letter, small letter, number, and special character. Please try again.',
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required.'),
    terms: Yup.boolean().oneOf([true], 'Terms & Conditions and Privacy Policy is required'),
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    const data = {
      e_type: 'seller',
      v_fname: values.firstName,
      v_lname: values.lastName,
      v_email: values.email.trim(),
      password: values.password,
      password_confirmation: values.confirmPassword,
      term_and_condition: values.terms === true ? 1 : 0,
      contest_to_marketing: values.marketing === true ? 1 : 0,
    };

    if (referralCode) {
      data.referral_code = referralCode;
    }

    const res = await AxiosAll('post', 'signup', data);
    if (res?.data?.statusCode === 200) {
      // await signup(values);
      navigate('/login');
      notify(res?.data?.message, 'success');
      formik.resetForm();
    } else {
      notify(res?.data?.message, 'error');
    }
    setIsLoading(false);
  };

  const socialLogin = async (data) => {
    const res = await AxiosAll('post', 'socialLogin', data);

    if (res?.data?.statusCode === 200) {
      notify(notifyMessages?.signupSuccess, 'success');
      dispatch(
        setUser({
          token: res?.data?.data?.token,
          user: res?.data?.data?.user,
          social_type: data?.social_type,
        }),
      );

      if (res?.data?.data?.user?.skip === 0) {
        setProfile(null);
        setProvider('');
        navigate('/personalize');
      } else {
        navigate('/');
      }
    } else {
      notify(res.error, 'error');
    }
  };

  useEffect(() => {
    if (!userDetails?.token && provider === 'google') {
      const googleData = {
        v_fname: profile?.given_name,
        v_lname: profile?.family_name,
        v_email: profile?.email,
        social_id: profile?.sub,
        social_type: provider,
        e_type: 'seller',
      };
      if (referralCode) {
        googleData.referral_code = referralCode;
      }

      socialLogin(googleData);
    } else if (!userDetails?.token && provider === 'apple') {
      const idTokenParts = profile?.authorization?.id_token?.split('.');
      const idTokenPayload = JSON.parse(atob(idTokenParts[1]));
      const userId = idTokenPayload?.sub;
      const appleData = {
        v_fname: profile?.user?.name?.firstName,
        v_lname: profile?.user?.name?.lastName,
        v_email: profile?.user?.email,
        social_id: userId,
        social_type: provider,
        e_type: 'seller',
      };
      if (referralCode) {
        appleData.referral_code = referralCode;
      }
      socialLogin(appleData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.token, provider, profile]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    globalWindow?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="signuptab">
      <form onSubmit={formik.handleSubmit}>
        <TextFieldInput
          placeholder="First Name"
          name="firstName"
          type="text"
          id="firstName"
          value={formik.values.firstName?.replaceAll(' ', '')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextFieldInput
          placeholder="Last Name"
          name="lastName"
          type="text"
          id="lastName"
          value={formik.values.lastName?.replaceAll(' ', '')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextFieldInput
          placeholder="Email Address"
          name="email"
          type="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextFieldInput
          password
          placeholder="Password"
          name="password"
          type={isPasswordShow ? 'password' : 'text'}
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          isPasswordShow={isPasswordShow}
          setIsPasswordShow={setIsPasswordShow}
        />
        <TextFieldInput
          password
          placeholder="Confirm Password"
          name="confirmPassword"
          type={isConfirmShow ? 'password' : 'text'}
          id="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          isPasswordShow={isConfirmShow}
          setIsPasswordShow={setIsConfirmShow}
        />
        <div className="tnccheckboxlogin">
          <div className="tnccheckboxlogin">
            <CheckBoxFiled
              label={
                <span>
                  By creating an account, I agree to the
                  <a href="/terms-and-conditions" className="hyper_link" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                  and
                  <a href="/privacy-policy" className="hyper_link" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  .
                </span>
              }
              name="terms"
              value={formik.values.terms}
              checked={formik.values.terms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.terms && Boolean(formik.errors.terms)}
              helperText={formik.touched.terms && formik.errors.terms}
            />
            <CheckBoxFiled
              // label="Consent to Marketing "
              label="Sign up for updates, offers & marketing material. You can unsubscribe at any time."
              name="marketing"
              value={formik.values.marketing}
              checked={formik.values.marketing}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.marketing && Boolean(formik.errors.marketing)}
              helperText={formik.touched.marketing && formik.errors.marketing}
            />
          </div>
        </div>
        <DarkBtn title="Get Started" type="submit" loading={isLoading} />
        <p className="ortext">or</p>
        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GOOGLE_APP_ID || ''}
          scope="openid profile email"
          redirect_uri="http://localhost:3005"
          onResolve={(resolver) => {
            setProvider(resolver?.provider);
            setProfile(resolver?.data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          {/* <GoogleLoginButton /> */}
          <div className="whitebtn">
            <img src={images.google} alt="search icon" className="google-icon" />
            Continue with Google
          </div>
        </LoginSocialGoogle>
        <LoginSocialApple
          client_id={process.env.REACT_APP_APPLE_ID || ''}
          scope="name email"
          redirect_uri={REDIRECT_URI}
          onResolve={(resolver) => {
            setProvider(resolver?.provider);
            setProfile(resolver?.data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          {/* <AppleLoginButton /> */}
          <div className="whitebtn">
            <img src={images.apple} alt="search icon" className="google-icon" />
            Continue with Apple
          </div>
        </LoginSocialApple>
      </form>
    </div>
  );
}
