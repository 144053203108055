import React, { useState } from 'react';
import { Loader } from '../../components/universal/Loader';

const Directory = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div>
      <Loader loading={loading} message="Loading..." />
      <h2 className="mainheadart font40px">Directory</h2>
      <iframe
        onLoad={() => setLoading(false)}
        title="iframe"
        className="airtable-embed"
        src="https://airtable.com/embed/appjx8Otpc6y7ffCY/shrg5T7u0IX0xK1nf?backgroundColor=gray&viewControls=on"
        width="100%"
        height="633"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      />
    </div>
  );
};

export default Directory;
