import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ChangePassword } from '../../components/dialogs/ChangePassword';
import { UpgradePlanContainer } from '../../components/universal/BlurContainer';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CustomizedSwitches } from '../../components/universal/Buttons/toggle';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import TextFieldInput from '../../components/universal/InputTypeText/TextField';
import Share from '../../components/universal/Share';
import InputFileUpload from '../../components/universal/UploadFile';
import { notifyMessages } from '../../core/constant';
import { updateUser } from '../../redux/actions/user';
import { request } from '../../services/request';
import notify from '../../utils/notify';

const AccountSettings = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profilePicture: '',
    profileBanner: '',
  });

  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [switchState, setSwitchState] = useState({
    marketing_communications: false,
    platform_updates: false,
  });
  const [isDisabled, setIsDisabled] = useState({
    userInfo: true,
    profilePicture: true,
    profileBanner: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profilePictureRef = useRef();
  const profileBannerRef = useRef();
  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  const updateUserProfile = async (data) => {
    setLoading(true);
    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data,
        token: userDetails?.token,
      });
      getUserProfile();
      dispatch(updateUser(res?.data));
      notify(res?.message, 'success');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/getProfile',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      setProfileData(res?.data);
      setUser((prev) => ({
        ...prev,
        firstName: res?.data?.v_fname || '',
        lastName: res?.data?.v_lname || '',
        email: res?.data?.v_email || '',
        profilePicture: res?.data?.v_image || '',
        profileBanner: res?.data?.v_profile_banner_image || '',
      }));
      setSwitchState({
        marketing_communications: res?.data?.marketing_communications === 1,
        platform_updates: res?.data?.platform_updates === 1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   setSwitchState({
  //     marketing_communications: profileData?.marketing_communications === 1,
  //     platform_updates: profileData?.platform_updates === 1,
  //   });
  // }, [profileData]);

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.profileBanner instanceof File && user?.profilePicture instanceof File) {
      setUser((prev) => ({
        ...prev,
        firstName: userDetails?.user?.v_fname,
        lastName: userDetails?.user?.v_lname,
        email: userDetails?.user?.v_email,
      }));
    } else if (user?.profilePicture instanceof File && !(user?.profileBanner instanceof File)) {
      setUser((prev) => ({
        ...prev,
        firstName: userDetails?.user?.v_fname,
        lastName: userDetails?.user?.v_lname,
        email: userDetails?.user?.v_email,
        profileBanner: userDetails?.user?.v_profile_banner_image,
      }));
    } else if (user?.profileBanner instanceof File && !(user?.profilePicture instanceof File)) {
      setUser((prev) => ({
        ...prev,
        firstName: userDetails?.user?.v_fname,
        lastName: userDetails?.user?.v_lname,
        email: userDetails?.user?.v_email,
        profilePicture: userDetails?.user?.v_image,
      }));
    } else {
      setUser({
        firstName: userDetails?.user?.v_fname,
        lastName: userDetails?.user?.v_lname,
        email: userDetails?.user?.v_email,
        profilePicture: userDetails?.user?.v_image,
        profileBanner: userDetails?.user?.v_profile_banner_image,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profileBanner, user?.profilePicture]);

  const handleEdit = (field) => {
    setIsDisabled({ ...isDisabled, [field]: !isDisabled[field] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleSwitchChange = (e) => {
    const { name } = e.target;
    setSwitchState((prev) => ({ ...prev, [name]: !switchState[name] }));
    updateUserProfile({ [name]: switchState[name] ? 0 : 1 });
  };

  const handleSave = () => {
    if (!user?.firstName || !user?.lastName || !user?.email) {
      notify(notifyMessages?.emptyFieldsMsg, 'error');
      return;
    }
    const formData = new FormData();
    formData.append('v_fname', user?.firstName);
    formData.append('v_lname', user?.lastName);
    // formData.append('v_email', user?.email);
    if (user?.profilePicture instanceof File) {
      formData.append('v_image', user?.profilePicture);
    }
    if (user?.profileBanner instanceof File) {
      formData.append('v_profile_banner_image', user?.profileBanner);
    }
    updateUserProfile(formData);
    // setIsDisabled({ ...isDisabled, [field]: !isDisabled[field] });
  };

  const handleRemoveProfilePic = () => {
    if (user?.profilePicture) {
      profilePictureRef.current.value = '';
      setUser((prev) => ({ ...prev, profilePicture: '' }));
      setIsDisabled({ ...isDisabled, profilePicture: true });
    }
  };

  const handleRemoveBannerPic = () => {
    if (user?.profileBanner) {
      profileBannerRef.current.value = '';
      setUser((prev) => ({ ...prev, profileBanner: '' }));
      setIsDisabled({ ...isDisabled, profileBanner: true });
    }
  };

  const handleProfilePicURL = (image) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image;
  };

  useEffect(() => {
    if (user?.profilePicture instanceof File && !(user?.profileBanner instanceof File)) {
      setIsDisabled((prev) => ({ ...prev, profilePicture: false }));
    } else if (user?.profileBanner instanceof File && !(user?.profilePicture instanceof File)) {
      setIsDisabled((prev) => ({ ...prev, profileBanner: false }));
    } else if (user?.profilePicture instanceof File && user?.profileBanner instanceof File) {
      setIsDisabled((prev) => ({ ...prev, profilePicture: false }));
      setIsDisabled((prev) => ({ ...prev, profileBanner: false }));
    } else {
      setIsDisabled((prev) => ({ ...prev, profilePicture: true }));
      setIsDisabled((prev) => ({ ...prev, profileBanner: true }));
    }
  }, [user?.profileBanner, user?.profilePicture]);

  return (
    <div className="myprofilerightsection">
      <Stack direction="row" alignItems="center" sx={{ textDecoration: 'underline' }}>
        {/* <ChevronLeftIcon /> */}
        <Typography
          variant="body1"
          className="font14px "
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/dashboard')}
        >
          {'<'} Return to Portfolio
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        className="my-account-new"
      >
        <h2 className="font25px maintitle">My Account</h2>
        <Stack direction="row" alignItems="center" spacing={3}>
          {/* <WhiteBtn
            title="Preview Profile"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_USER_APP_URL}artist/${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}/${userDetails?.user?.id}`;
            }}
          /> */}
          <DarkBtn
            title="Save & Publish"
            onClick={handleSave}
            loading={loading}
            loadingText="Saving..."
            disabled={loading}
          />
        </Stack>
      </Stack>
      <div className="profilesection">
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5} className="input-upload-section">
          <div className="upload_section">
            <InputFileUpload
              title="Add Profile Picture"
              pictureRef={profilePictureRef}
              image={user?.profilePicture ? handleProfilePicURL(user?.profilePicture) : userDetails?.user?.v_image}
              onChange={(e) => {
                if (e.target.files[0].size > 2097152) {
                  notify(notifyMessages?.fileTooLarge, 'error');
                  return;
                }
                setUser((prev) => ({
                  ...prev,
                  profilePicture: e.target.files[0],
                }));
              }}
              alt="Profile Picture"
            />
            <Stack direction="row" alignItems="center" spacing={3}>
              {/* {isDisabled?.profilePicture ? (
                <DarkBtn
                  title="Change"
                  onClick={() => {
                    profilePictureRef?.current?.click();
                  }}
                />
              ) : (
                <DarkBtn title="Save" onClick={() => handleSave('profilePicture')} />
              )} */}
              {user?.profilePicture instanceof File && !isDisabled?.profilePicture && (
                <Typography
                  onClick={handleRemoveProfilePic}
                  variant="body1"
                  className="font16px"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Remove
                </Typography>
              )}
            </Stack>
          </div>
          {/* {plan?.plan_type === 'paid' ? ( */}
          <div className="upload_section_two">
            <InputFileUpload
              title="Add Profile Banner"
              pictureRef={profileBannerRef}
              image={
                user?.profileBanner
                  ? handleProfilePicURL(user?.profileBanner)
                  : userDetails?.user?.v_profile_banner_image
              }
              onChange={(e) => {
                if (e.target.files[0].size > 2097152) {
                  notify(notifyMessages?.fileTooLarge, 'error');
                  return;
                }
                setUser((prev) => ({
                  ...prev,
                  profileBanner: e.target.files[0],
                }));
              }}
              alt="Profile Banner"
            />
            <Stack direction="row" alignItems="center" spacing={3}>
              {/* {isDisabled?.profileBanner ? (
                  <DarkBtn
                    title="Change"
                    onClick={() => {
                      profileBannerRef?.current?.click();
                    }}
                  />
                ) : (
                  <DarkBtn title="Save" onClick={() => handleSave('profileBanner')} />
                )} */}
              {user?.profileBanner instanceof File && !isDisabled?.profileBanner && (
                <Typography
                  onClick={handleRemoveBannerPic}
                  variant="body1"
                  className="font16px"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Remove
                </Typography>
              )}
            </Stack>
          </div>
          {/* ) : (
            <UpgradePlanContainer
              btnNavigation="/my-subscription"
              btnTitle="Upgrade your plan"
              mainTitle="Upgrade to access profile banner"
            />
          )} */}
        </Stack>
      </div>
      <div className="profilesection">
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
          <div className="nameemailsection">
            <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
              First Name
            </Typography>
            <TextField
              placeholder="First Name"
              name="firstName"
              type="text"
              value={user?.firstName?.trim()}
              onChange={handleChange}
              // disabled={isDisabled?.userInfo}
            />
          </div>
          <div className="nameemailsection">
            <Typography className="font18px" variant="body1" sx={{ mb: 1 }}>
              Last Name
            </Typography>
            <TextField
              placeholder="Last Name"
              type="text"
              name="lastName"
              value={user?.lastName?.trim()}
              onChange={handleChange}
              // disabled={isDisabled?.userInfo}
            />
          </div>
        </Stack>
        <div className="nameemailsection">
          <Typography className="font18px" variant="body1" sx={{ mb: 1 }}>
            Email
          </Typography>
          <TextField
            placeholder="Email Address"
            type="email"
            name="email"
            value={user?.email}
            onChange={handleChange}
            disabled
          />
        </div>
        {/* <Stack className="edit_section_new" sx={{ mt: 4 }}>
          {isDisabled?.userInfo ? (
            <DarkBtn title="Edit" onClick={() => handleEdit('userInfo')} />
          ) : (
            <DarkBtn title="Save" onClick={() => handleSave('userInfo')} />
          )}
        </Stack> */}
      </div>

      {/* <div className="requestlinksection">
        <p className="font20px">
          Verify Your ID <InfoOutlinedIcon />{' '}
        </p>
        <DarkBtn title="Start Verification" />
      </div> */}
      <div className="requestlinksection">
        <p className="font20px">Need to change your password?</p>
        <ChangePassword />
      </div>
      <CardContainer paddingX={28} paddingY={33} className="shareuri">
        <TextFieldInput
          type="text"
          value={`${process.env.REACT_APP_USER_APP_URL}artist/${profileData?.v_fname}-${profileData?.v_lname}/${profileData?.user_info?.i_user_id}`}
        />
        <Share
          url={`${process.env.REACT_APP_USER_APP_URL}artist/${profileData?.v_fname}-${profileData?.v_lname}/${profileData?.user_info?.i_user_id}`}
          title="Share Profile URL"
          onClick={(handleOpen) => {
            if (!profileData?.user_info?.i_user_id) {
              notify(notifyMessages?.completeProfileMsg, 'error');
              navigate('/personalize');
              return;
            }
            handleOpen();
          }}
        />
      </CardContainer>
      <CardContainer paddingX="3rem" gap={0} className="profile-checks">
        <Typography variant="h6" className="check-profile">
          Contact Preferences
        </Typography>
        <div className="profile-switch">
          <Typography variant="h6">Marketing Communications</Typography>
          <CustomizedSwitches
            name="marketing_communications"
            checked={switchState.marketing_communications}
            onChange={handleSwitchChange}
          />
        </div>
        <div className="profile-switch">
          <Typography variant="h6">Platform Updates</Typography>
          <CustomizedSwitches
            name="platform_updates"
            checked={switchState.platform_updates}
            onChange={handleSwitchChange}
          />
        </div>
      </CardContainer>
    </div>
  );
};

export default AccountSettings;
